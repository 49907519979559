import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Contexts/AuthProvider";
import { adminRoutes, routes } from "./routes";
import { muiTheme } from "./Styles/theme";

const queryClient = new QueryClient();

function App() {
  console.log(process.env.NODE_ENV);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <div className="App">
          <BrowserRouter>
            <Suspense fallback={"Loading"}>
              <Routes>
                {routes.map((route) => (
                  <Route path={route.path} element={<route.component />} />
                ))}
              </Routes>

              <AuthProvider>
                <Routes>
                  {adminRoutes.map((route) => (
                    <Route path={route.path} element={<route.component />} />
                  ))}
                </Routes>
              </AuthProvider>
            </Suspense>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
