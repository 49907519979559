/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebaseClient from "../Utils/firebaseClient";
import "firebase/firestore";

interface AuthContextProps {
  user: firebaseClient.User | null;
  signIn: (
    email: string,
    password: string
  ) => Promise<firebaseClient.User | null>;
  signOut: () => void;
  startSession: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  signIn: async () => null,
  signOut: async () => {},
  startSession: async () => {},
});

export function AuthProvider({ children }: any) {
  const [userLogged, setUserLogged] = useState<firebaseClient.User | null>(
    null
  );
  const [user, setUser] = useState<firebaseClient.User | null>(null);
  const [sessionStart, setSessionStart] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const signIn = async (
    email: string,
    password: string
  ): Promise<firebaseClient.User | null> => {
    try {
      const result = await firebaseClient
        .auth()
        .signInWithEmailAndPassword(email, password);

      const adminUsersDocs = await firebaseClient
        .firestore()
        .collection("admin_users")
        .where("email", "==", email)
        .get();

      if (adminUsersDocs.docs.length < 1) {
        alert("Admin user not found");
        return null;
      }

      setUserLogged(result.user);

      return result.user;
    } catch (error: any) {
      alert(error.message);
      return null;
    }
  };

  const signOut = async () => {
    await firebaseClient.auth().signOut();
    clearSession();
  };

  const startSession = async () => {
    if (userLogged) {
      setSessionStart(true);
      setUser(userLogged);
      const token = (await userLogged?.getIdToken()) || "";
      window.localStorage.setItem("t", token);
    }
  };

  const clearSession = () => {
    setUser(null);
    setSessionStart(false);
    setUserLogged(null);
    window.localStorage.removeItem("t");
    navigate("/admin-portal/login");
  };

  useEffect(() => {
    const unsubscribe = firebaseClient
      .auth()
      .onAuthStateChanged(async (user) => {
        if (sessionStart) {
          setUser(user);
          const token = (await user?.getIdToken()) || "";
          window.localStorage.setItem("t", token);
        }
      });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (sessionStart) {
        const localToken = window.localStorage.getItem("t");
        if (localToken) {
          userLogged?.reload();
        } else {
          clearSession();
        }
      }
    }, 1000 * 60 * 15);
  }, []);

  useEffect(() => {
    const localToken = window.localStorage.getItem("t");
    if (!user && pathname.includes("admin-portal") && !localToken) {
      clearSession();
    }
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{ user: user, signIn: signIn, signOut: signOut, startSession }}
    >
      {children}
    </AuthContext.Provider>
  );
}
