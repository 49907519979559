import firebase from "firebase/app";
import "firebase/auth";
import { firebaseClientConfig } from "../Config/firebase";

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp(firebaseClientConfig);
  // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
}

export default firebase;
