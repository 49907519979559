import React from "react";

const EntryPage = React.lazy(
  () => import("./Components/Views/EntryPage/EntryPage")
);
const PrivacyPolicyPage = React.lazy(
  () => import("./Components/Views/PrivacyPolicy/PrivacyPolicyPage")
);

// Admin Portal
const AdminPortalHomePage = React.lazy(
  () => import("./Components/Views/AdminPortal/Home/HomePage")
);
const ReportsPage = React.lazy(
  () => import("./Components/Views/AdminPortal/Reports/ReportsPage")
);
const LeaguesPage = React.lazy(
  () => import("./Components/Views/AdminPortal/Leagues/LeaguesPage")
);
const AuthPage = React.lazy(
  () => import("./Components/Views/AdminPortal/Auth/AuthPage")
);

export const routes: { path: string; component: any }[] = [
  {
    path: "/",
    component: EntryPage,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
  },
];

export const adminRoutes: { path: string; component: any }[] = [
  {
    path: "/admin-portal",
    component: AdminPortalHomePage,
  },
  {
    path: "/admin-portal/login",
    component: AuthPage,
  },
  {
    path: "/admin-portal/reports",
    component: ReportsPage,
  },
  {
    path: "/admin-portal/leagues",
    component: LeaguesPage,
  },
];
